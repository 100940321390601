import { template as template_8eea522a9652426a815a105d79f1f98c } from "@ember/template-compiler";
const FKText = template_8eea522a9652426a815a105d79f1f98c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
