import { template as template_c6156ef45d60469dafc36d915d5e77ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_c6156ef45d60469dafc36d915d5e77ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
